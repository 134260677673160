import { HttpClient } from '@angular/common/http';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { catchError, filter, map, of, zip } from 'rxjs';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { getCurrency } from './app/@shared/store/prices/prices.selector';
import { Injector } from '@angular/core';
import { ErrorHandlerService } from './app/@shared/services/errorhandler.service';
import { environment } from './environments/environment';

export const supportedLanguages = [
  { code: 'de', short: 'De', name: 'Deutsch' },
  { code: 'en', short: 'En', name: 'English' }
];

/**
 * load the global translation files via HTTP client from the assets directory and extends that object
 * with special translations from the skins directory (if there is a special translation for that skin (which is legacy))
 */
export class TranslateCustomLoader implements TranslateLoader {
  constructor(private readonly http: HttpClient) { }

  getTranslation(lang: string) {
    return zip(
      this.http.get('/assets/i18n/' + lang + '.json'),
      this.http.get('/skin/i18n/' + lang + '.json').pipe(
        catchError(() => {
          // error is okay here as we do not neccassary have a skin translation anymore
          return of({});
        })
      )
    ).pipe(
      map(([globalTrans, skinTrans]) => _.merge(globalTrans, skinTrans))
    );
  }
}

/**
 * dynamically defines the angular locale ID, based on the current language in @ngx/translate
 */
export class DynamicLocaleId extends String {
  private lang = this.translate.currentLang;

  constructor(protected translate: TranslateService) {
    super('');
    this.translate.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
    });
  }

  override toString() {
    return this.lang === 'de' ? 'de-DE' : 'en-EN';
  }
}

/**
 * dynamically defines the angular currency code, based on the value stored in the store (given by ugly ProjectName hack)
 */
export class DynamicCurrencyCode extends String {
  private currency = 'USD';

  constructor(private readonly store: Store) {
    super('');

    this.store
      .select(getCurrency)
      .pipe(filter(currency => currency !== undefined))
      .subscribe(currency => (this.currency = currency));
  }

  override toString() {
    return this.currency;
  }
}

/**
 * handles missing translations
 */
export class AppMissingTranslationHandler implements MissingTranslationHandler {
  constructor(private readonly injector: Injector) { }

  handle(params: MissingTranslationHandlerParams) {
    const errorHandlerService = this.injector.get(ErrorHandlerService);

    if (_.has(params, 'key') && _.isString(params.key) && params.key !== 'ERRORS.UNKNOWN_ERROR_HEAD') {
      if (params.key.match(/^[A-Z0-9._]+\.[A-Za-z0-9._]+$/)) {
        const lang = _.get(params, 'translateService.store.currentLang');
        errorHandlerService.log({
          summary: 'Missing Translation: ' + params.key + ' (language: ' + lang + ')',
          displayToast: false
        });

        if (!environment.production) {
          console.warn('Missing translation!', params.key, lang);
        }
      }
      return params.key;
    }

    return '';
  }
}
